import React from "react";
import Navbar from "../Navbar";

import "./style.scss";

const Header = () => {
  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-transparent"
    >
      <div className="container d-flex justify-content-between align-items-center">
        <div id="logo">
          <a href="/#" aria-label="Therapp logo">
            <img src="assets/img/therapp-white-logo.png" alt="" />
          </a>
        </div>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
