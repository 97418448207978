import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

export const PageWrapper = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
