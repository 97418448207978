import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const Footer = () => {
  const [t] = useTranslation("footer");

  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-lg-start text-center">
            <div className="copyright">
              © Copyright <strong>Therapp S.A.</strong>
            </div>
          </div>
          <div className="col-lg-6">
            <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
              <a href="/#" aria-label="Return to home section">
                {t("home")}
              </a>
              <a href="/about" aria-label="Read more about Therapp">
                {t("about")}
              </a>
              <a
                href="https://therapp.health/privacy-policy"
                target="_blank"
                rel="noreferrer"
                aria-label="Read about our Privacy Policy"
              >
                {t("privacy-policy")}
              </a>
              <a
                href="https://therapp.health/terms-conditions"
                target="_blank"
                rel="noreferrer"
                aria-label="Read about our Terms & Conditions"
              >
                {t("terms-conditions")}
              </a>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
