import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import i18n from "i18next";

import "./style.scss";

const VIDEO_URL_EN =
  "https://therapp.s3.amazonaws.com/videos/landing/Therapp+Intro+Landing+-+EN.mp4";
const VIDEO_URL_ES =
  "https://therapp.s3.amazonaws.com/videos/landing/Therapp+Intro+Landing+-+ES.mp4";

const Video = () => {
  const [video, setVideo] = useState(VIDEO_URL_ES);

  useEffect(() => {
    var language = localStorage.getItem("i18nextLng");
    if (language === "es") {
      setVideo(VIDEO_URL_ES);
    } else {
      setVideo(VIDEO_URL_EN);
    }

    //Set the correct video on language change
    i18n.on("languageChanged", () => {
      language = localStorage.getItem("i18nextLng");
      if (language === "es") {
        setVideo(VIDEO_URL_ES);
      } else {
        setVideo(VIDEO_URL_EN);
      }
    });
  }, [setVideo]);

  return (
    <section className="section">
      <div className="container">
        <div className="video" data-aos="fade">
          <ReactPlayer controls url={video} width="100%" height="100%" />
        </div>
      </div>
    </section>
  );
};

export default Video;
