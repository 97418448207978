import React from "react";
import { useTranslation } from "react-i18next";
import { APP_BASE_URL } from "../../../../secrets";

import "./style.scss";

const SignUp = () => {
  const [t] = useTranslation("signUp");

  return (
    <section id="call-to-action">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 text-center text-lg-start">
            <h3 className="cta-title">{t("title")}</h3>
            <p className="cta-text">{t("description")}</p>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a
              className="cta-btn align-middle"
              href={`${APP_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
            >
              {t("button")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
