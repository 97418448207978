import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const Workflow = () => {
  const [t] = useTranslation("workflow");

  return (
    <section className="section">
      <div className="container">
        <div
          className="row justify-content-center text-center mb-5"
          data-aos="fade"
        >
          <div className="col-md-6 mb-5">
            <img
              src="assets/img/undraw_svg_1.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row">
          <div className="d-flex col-lg-3 col-md-6">
            <div className="step">
              <span className="number">01</span>
              <h4>{t("title-1")}</h4>
              <p>{t("description-1")}</p>
            </div>
          </div>
          <div className="d-flex col-lg-3 col-md-6">
            <div className="step">
              <span className="number">02</span>
              <h4>{t("title-2")}</h4>
              <p>{t("description-2")}</p>
            </div>
          </div>
          <div className="d-flex col-lg-3 col-md-6">
            <div className="step">
              <span className="number">03</span>
              <h4>{t("title-3")}</h4>
              <p>{t("description-3")}</p>
            </div>
          </div>
          <div className="d-flex col-lg-3 col-md-6">
            <div className="step">
              <span className="number">04</span>
              <h4>{t("title-4")}</h4>
              <p>{t("description-4")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Workflow;
