import React, { useState, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getMenuItems from "./getMenuItems";

import "./style.scss";

const Navbar = () => {
  const [t] = useTranslation("navbar");

  const location = useLocation();

  const [menuItems, setMenuItems] = useState([]);

  const [activeItem, setActiveItem] = useState(0);

  const loadMenuItems = useCallback(() => {
    setMenuItems(getMenuItems(location.pathname));
  }, [location]);

  useMemo(() => {
    loadMenuItems();
  }, [loadMenuItems]);

  const handleDropdownItemClick = (e) => {
    e.preventDefault();
  };

  const handleItemClick = (index) => {
    setActiveItem((prevActiveItem) => {
      return prevActiveItem !== index ? index : null;
    });
  };
  //Handle touch input from mobile devices
  const handleToggleMobileMenu = () => {
    document.querySelector("#navbar").classList.toggle("navbar-mobile");
    let menuButton = document.querySelector(".mobile-nav-toggle");
    menuButton.classList.toggle("bi-list");
    menuButton.classList.toggle("bi-x");
  };

  //Handle touch input from mobile devices
  const handleTouchOnDropdown = (e) => {
    let navbar = document.querySelector("#navbar");
    if (navbar.classList.contains("navbar-mobile")) {
      e.currentTarget.nextElementSibling.classList.toggle("dropdown-active");
    }
  };

  const renderMenuItems = (items, isSubmenu) => {
    return items.map((item, index) => {
      if (item.subItems && item.subItems.length > 0) {
        return (
          <li key={index} className="dropdown">
            <a
              onTouchStart={window.isMobile ? handleTouchOnDropdown : () => {}}
              href={item.href}
              onClick={!window.isMobile ? handleDropdownItemClick : () => {}}
            >
              <span>{t(item.title)}</span> <i className="bi bi-chevron-down" />
            </a>
            <ul>{renderMenuItems(item.subItems, true)}</ul>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <a
              className={
                isSubmenu
                  ? null
                  : `nav-link ${
                      location.pathname === "/" && item.target === null
                        ? `scrollto `
                        : ""
                    }${activeItem === index ? "active" : ""}`
              }
              onClick={
                item.function ? item.function : () => handleItemClick(index)
              }
              href={item.href}
              target={item.target}
              rel={item.rel}
            >
              {t(item.title)}
            </a>
          </li>
        );
      }
    });
  };

  return (
    <nav id="navbar" className="navbar">
      <ul>{renderMenuItems(menuItems, false)}</ul>
      <i
        onTouchStart={window.isMobile ? handleToggleMobileMenu : () => {}}
        onClick={!window.isMobile ? handleToggleMobileMenu : () => {}}
        className="bi bi-list mobile-nav-toggle"
      />
    </nav>
  );
};

export default Navbar;
