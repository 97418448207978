import i18n from "i18next";
import { APP_BASE_URL } from "../../secrets";

const changeLanguage = (e, language) => {
  e.preventDefault();
  i18n.changeLanguage(language);
};

const getMenuItems = (parameter) => {
  if (parameter === "/") {
    return [
      { title: "home", href: "#hero" },
      { title: "features", href: "#features" },
      { title: "pricing", href: "#pricing" },
      { title: "contact", href: "#contact" },
      {
        title: "log-in-button",
        href: `${APP_BASE_URL}`,
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "dropdown",
        href: "/#",
        subItems: [
          { title: "dropdown-1", href: "/about" },
          {
            title: "dropdown-2",
            href: "https://app.therapp.health/terms-conditions",
            target: "_blank",
            rel: "noreferrer",
          },
          {
            title: "dropdown-3",
            href: "https://app.therapp.health/privacy-policy",
            target: "_blank",
            rel: "noreferrer",
          },
        ],
      },
      {
        title: "language",
        href: "/#",
        subItems: [
          {
            title: "en",
            href: "/#",
            function: (e) => changeLanguage(e, "en"),
          },
          {
            title: "es",
            href: "/#",
            function: (e) => changeLanguage(e, "es"),
          },
        ],
      },
    ];
  } else {
    return [
      { title: "home", href: "/" },
      {
        title: "log-in-button",
        href: `${APP_BASE_URL}`,
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "dropdown",
        href: "/#",
        subItems: [
          { title: "dropdown-1", href: "/about" },
          {
            title: "dropdown-2",
            href: "https://app.therapp.health/terms-conditions",
            target: "_blank",
            rel: "noreferrer",
          },
          {
            title: "dropdown-3",
            href: "https://app.therapp.health/privacy-policy",
            target: "_blank",
            rel: "noreferrer",
          },
        ],
      },
      {
        title: "language",
        href: "/#",
        subItems: [
          {
            title: "en",
            href: "/#",
            function: (e) => changeLanguage(e, "en"),
          },
          {
            title: "es",
            href: "/#",
            function: (e) => changeLanguage(e, "es"),
          },
        ],
      },
    ];
  }
};

export default getMenuItems;
