import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useCallback } from "react";
import {
  APP_USE_PROMOS,
  APP_BASE_URL,
  APP_ENDPOINT_URL,
} from "../../../../secrets";

import "./style.scss";

const PRICE_AFTER_TAX_PERCENTAGE = 1 - 0.13;

const _calculatePromoDiscount = (originalPrice, promoPrice) =>
  Math.round(100 - (promoPrice / originalPrice) * 100, 2);

const Pricing = () => {
  const [t] = useTranslation("pricing");

  const [products, setProducts] = useState([]);

  const getSubscriptionProducts = useCallback(async () => {
    axios.get(`${APP_ENDPOINT_URL}/onvo/products`).then((response) => {
      const allPrices = response?.data?.data?.map(
        ({ price, description, ...product }) => {
          const [textKey] = description?.split("_");

          return {
            ...product,
            textKey,
            description,
            rawPrice: price,
            price: price / 100,
            isClinic: textKey === "clinic",
            isPromo: description?.includes("promo"),
          };
        }
      );

      const pricesToUse = APP_USE_PROMOS
        ? allPrices
            .filter(({ isPromo }) => isPromo)
            .map((promo) => ({
              ...promo,
              originalPrice: allPrices.find(
                ({ textKey, isPromo }) => promo?.textKey === textKey && !isPromo
              )?.price,
            }))
        : allPrices.filter(({ isPromo }) => !isPromo);

      setProducts(pricesToUse.sort((a, b) => a?.rawPrice - b?.rawPrice));
    });
  }, []);

  useEffect(() => {
    getSubscriptionProducts();
  }, [getSubscriptionProducts]);

  const PricingCard = ({
    price,
    textKey,
    id,
    isClinic,
    isPatient,
    originalPrice,
  }) => (
    <div className="d-flex col-lg-4 col-md-6">
      <div className={`box ${isPatient ? "box--patient" : ""}`}>
        <div className="top">
          <h3>{t(`${textKey}Title`)}</h3>
          {!isClinic && (
            <>
              <h4>
                {APP_USE_PROMOS && originalPrice && (
                  <>
                    <del>${originalPrice}</del>{" "}
                  </>
                )}
                {isPatient ? "$0" : `US$${price}`}
              </h4>
              {APP_USE_PROMOS && originalPrice && (
                <p>{`${_calculatePromoDiscount(originalPrice, price)}% ${t(
                  "discountText"
                )}`}</p>
              )}
              <p>{!isPatient && t(`${textKey}PromoMessage`)}</p>
            </>
          )}
        </div>
        <div className="middle">
          <ul>
            {t(`${textKey}PriceDetails`)
              .split("*")
              .filter(Boolean)
              .map((bullet, index) => (
                <li key={`${textKey} ${index}`}>
                  <i className="bi bi-check-circle" />
                  {bullet}
                </li>
              ))}
          </ul>
          {!isClinic && !isPatient && <p>{t(`${textKey}AdditionalNote`)}</p>}
        </div>
        <a
          href={
            id ? `${APP_BASE_URL}/PT/payment/${id}` : `${APP_BASE_URL}/signup`
          }
          className={`get-started-btn ${!isClinic ? "enabled" : "disabled"}`}
        >
          {t(isClinic ? "availableSoon" : "obtain")}
        </a>
      </div>
    </div>
  );

  const PatientCard = () => (
    <PricingCard price={0} textKey="patient" isPatient />
  );

  return (
    <section id="pricing" className="section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3 className="section-title">{t("title")}</h3>
          <span className="section-divider" />
          <p className="section-description">{t("description")}</p>
        </div>
        <div className="row">
          <PatientCard />
          {products?.map((product) => (
            <PricingCard
              {...product}
              key={product?.id}
              price={
                product?.price
                  ? (product?.price * PRICE_AFTER_TAX_PERCENTAGE).toFixed(2)
                  : "-"
              }
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
