import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PageWrapper } from "./containers/PageWrapper";
import Home from "./routes/Home";
import About from "./routes/About";
import FallbackRedirect from "./routes/CustomRedirect"

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <PageWrapper>
          <Home />
        </PageWrapper>
      </Route>
      <Route path="/about">
        <PageWrapper>
          <About />
        </PageWrapper>
      </Route>
      <Route>
        <FallbackRedirect />
      </Route>
    </Switch>
  </Router>
);

export default App;
