import { useHistory } from "react-router-dom";

import { APP_BASE_URL } from '../../secrets'

const CustomRedirect = () => {
  const history = useHistory();
  console.info("Redirecting")

  const newPath = `${APP_BASE_URL}${history?.location?.pathname}${history?.location?.search}`

  window.location.href = newPath;

  return null;
};

export default CustomRedirect;
