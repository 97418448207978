import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const Invite = () => {
  const [t] = useTranslation("invite");

  const subject = encodeURIComponent(t("emailSubject"));
  const body = encodeURIComponent(t("emailBody"));

  const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

  return (
    <section className="section">
      <div className="container invite">
        <div className="row align-items-center">
          <div className="col-md-4 me-auto">
            <h2 className="mb-4">{t("title")}</h2>
            <p className="mb-4 description">{t("description")}</p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href={mailtoLink}
                className="btn btn-primary"
              >
                {t("button")}
              </a>
            </p>
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <img
              src="assets/img/undraw_svg_2.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invite;
