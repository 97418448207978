import React from "react";
import { Container } from "react-bootstrap";

import "./style.scss";

const About = () => {
  return (
    <Container>
      <div className="about">
        <h4 className="title">
          <span>Acerca de Therapp</span>
        </h4>
        <p>
          <span>
            Therapp es la aplicación para fisioterapeutas en español más
            completa e innovadora. Nace de la necesidad de facilitar el proceso
            de rehabilitación física, el cual puede ser por sí mismo muy
            complejo para pacientes y fisioterapeutas.
            <br />
            <br />
          </span>
          <span>
            Quienes han estado involucrados en un proceso de rehabilitación,
            saben que se requiere mucho compromiso para obtener los resultados
            deseados y que ese compromiso no es fácil de lograr o mantener.
            Therapp le facilita a fisioterapeutas administrar a sus pacientes y
            realizar prescripciones de ejercicios de fisioterapia desde su
            teléfono móvil o computadora. Asimismo, facilita el compromiso de
            pacientes con su tratamiento fisioterapéutico y así mejorar los
            resultados de la terapia física.
            <br />
            <br />
          </span>
        </p>
        <h4 className="title">
          <span>Historia</span>
        </h4>
        <span>
          La idea de Therapp surge cuando uno de los fundadores, mientras
          trabajaba como fisioterapeuta, notó que las prescripciones de
          ejercicios fisioterapéuticos realizadas de forma tradicional no
          brindaban los mejores resultados:&nbsp;
          <br />
          <br />
        </span>
        <ul>
          <li>
            <span>
              Explicar los ejercicios oralmente para que pacientes los
              memoricen, frecuentemente terminan siendo olvidados.&nbsp;
              <br />
            </span>
          </li>
          <li aria-level={1}>
            <span>
              Algunos fisioterapeutas realizan sus prescripciones escritas e
              ilustradas a mano, pero esto requiere destrezas creativas.&nbsp;
            </span>
          </li>
          <li aria-level={1}>
            <span>
              Otra opción es enviar los ejercicios por medio de mensajes de
              texto, videos e ilustraciones tomadas de internet, pero de igual
              forma toma mucho tiempo y no es un método estandarizado.
              <br />
              <br />
            </span>
          </li>
        </ul>
        <p>
          <span>
            Es así como los cofundadores de Therapp unen esfuerzos en 2020 para
            comenzar a desarrollar una solución digital a este problema. En
            2022, Therapp gana el premio Reto PAIS, de entre más de 200
            propuestas, obteniendo así fondos de prototipado y acceso a un
            programa de incubación. En 2023, Therapp es lanzado a todo el
            mercado hispanohablante, con el objetivo de expandirse a todo el
            continente Americano y el resto del mundo.
            <br />
            <br />
          </span>
          <span>
            Therapp crece todos los días y pronto podrás acceder también a más
            funcionalidades.&nbsp;
            <br />
            <br />
          </span>
        </p>
        <h4 className="title">
          <span>Equipo de Therapp</span>
        </h4>
        <p>
          <span>
            Therapp es una tecnología creada en Costa Rica, por un equipo de
            profesionales en fisioterapia, diseño y tecnología.&nbsp;
            <br />
            <br />
          </span>
          <span>
            Ronald Maltés
            <br />
          </span>
          <span>
            CEO - Cofundador
            <br />
          </span>
          <span>
            Fisioterapeuta y Diseñador
            <br />
            <br />
          </span>
          <span>
            Alí Marchena
            <br />
          </span>
          <span>
            CTO - Cofundador
            <br />
          </span>
          <span>
            Desarrollador de Software
            <br />
            <br />
          </span>
          <span>
            Esteban Blanco
            <br />
          </span>
          <span>
            COO - Cofundador
            <br />
          </span>
          <span>
            Desarrollador de Software
            <br />
            <br />
          </span>
          <span>
            Sofía Camacho
            <br />
          </span>
          <span>
            Desarrolladora
            <br />
          </span>
          <span>
            Desarrolladora de Software y Biotecnóloga
            <br />
            <br />
          </span>
          <span>
            Alonso Oreamuno
            <br />
          </span>
          <span>
            Desarrollador
            <br />
          </span>
          <span>Desarrollador de Software</span>
        </p>
      </div>
    </Container>
  );
};

export default About;
