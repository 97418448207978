import React from "react";

import Hero from "./sections/Hero";
import Features from "./sections/Features";
import Workflow from "./sections/Workflow";
import Invite from "./sections/Invite";
import SignUp from "./sections/SignUp";
import Pricing from "./sections/Pricing";
import Contact from "./sections/Contact";
import Video from "./sections/Video";

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <Video />
      <Workflow />
      <Invite />
      <SignUp />
      <Pricing />
      <Contact />
    </>
  );
};

export default Home;
