import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import reportWebVitals from "./reportWebVitals";
import resources from "./translations/resources";

import "./assets/scss/index.scss";
import "./assets/js/index.js";

const DETECTION_OPTIONS = {
  order: ["localStorage"],
  caches: ["localStorage"],
};

i18next.use(LanguageDetector).init({
  detection: DETECTION_OPTIONS,
  interpolation: { escapeValue: false },
  fallbackLng: "es",
  resources,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
