import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const Features = () => {
  const [t] = useTranslation("features");

  return (
    <section id="features">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-8 offset-lg-4">
            <div className="section-header">
              <h2 className="section-title">{t("title")}</h2>
              <span className="section-divider" />
            </div>
          </div>
          <div className="col-lg-4 col-md-5 justify-content-center align-self-center features-img">
            <img
              src="assets/img/product-features.png"
              alt=""
              data-aos="fade-right"
            />
          </div>
          <div className="col-lg-8 col-md-7 ">
            <div className="row">
              <div className="col-lg-6 col-md-6 box" data-aos="fade-up">
                <div className="icon">
                  <img
                    src="assets/img/Icon_1_adminPatients.svg"
                    alt="Patients administration"
                  />
                </div>
                <h3 className="title">
                  <span>{t("subtitle-1")}</span>
                </h3>
                <p className="description">{t("description-1")}</p>
              </div>
              <div className="col-lg-6 col-md-6 box" data-aos="fade-up">
                <div className="icon">
                  <img
                    src="assets/img/Icon_2_ExercisesCatalog.svg"
                    alt="Exercise catalog"
                  />
                </div>
                <h3 className="title">
                  <span>{t("subtitle-2")}</span>
                </h3>
                <p className="description">{t("description-2")}</p>
              </div>
              <div
                className="col-lg-6 col-md-6 box"
                data-aos="fade-up"
                dat-aos-delay={100}
              >
                <div className="icon">
                  <img
                    src="assets/img/Icon_3_SendPrescription.svg"
                    alt="Send a prescription"
                  />
                </div>
                <h3 className="title">
                  <span>{t("subtitle-3")}</span>
                </h3>
                <p className="description">{t("description-3")}</p>
              </div>
              <div
                className="col-lg-6 col-md-6 box"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon">
                  <img
                    src="assets/img/Icon_4_ImproveResults.svg"
                    alt="Improve results on patients"
                  />
                </div>
                <h3 className="title">
                  <span>{t("subtitle-4")}</span>
                </h3>
                <p className="description">{t("description-4")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
