// en
// components
import footer_en from "./en/components/footer.json";
import navbar_en from "./en/components/navbar.json";
// routes
import hero_en from "./en/routes/home/hero.json";
import features_en from "./en/routes/home/features.json";
import workflow_en from "./en/routes/home/workflow.json";
import invite_en from "./en/routes/home/invite.json";
import signUp_en from "./en/routes/home/signUp.json";
import pricing_en from "./en/routes/home/pricing.json";
import contact_en from "./en/routes/home/contact.json";

// es
// components
import footer_es from "./es/components/footer.json";
import navbar_es from "./es/components/navbar.json";
// routes
import hero_es from "./es/routes/home/hero.json";
import features_es from "./es/routes/home/features.json";
import workflow_es from "./es/routes/home/workflow.json";
import invite_es from "./es/routes/home/invite.json";
import signUp_es from "./es/routes/home/signUp.json";
import pricing_es from "./es/routes/home/pricing.json";
import contact_es from "./es/routes/home/contact.json";

const resources = {
  en: {
    footer: footer_en,
    navbar: navbar_en,
    hero: hero_en,
    features: features_en,
    workflow: workflow_en,
    invite: invite_en,
    signUp: signUp_en,
    pricing: pricing_en,
    contact: contact_en,
  },
  es: {
    footer: footer_es,
    navbar: navbar_es,
    hero: hero_es,
    features: features_es,
    workflow: workflow_es,
    invite: invite_es,
    signUp: signUp_es,
    pricing: pricing_es,
    contact: contact_es,
  },
};

export default resources;
