import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import axios from "axios";
import { APP_ENDPOINT_URL } from "../../../../secrets";

import "./style.scss";

const Contact = () => {
  const [t] = useTranslation("contact");

  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();
  useForm();

  const onSubmit = (data) => {
    axios
      .post(`${APP_ENDPOINT_URL}/common/email/contact`, data, {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setEmailSent(true);
          setEmailError(false);
        } else {
          setEmailSent(false);
          setEmailError(true);
        }
      })
      .catch((error) => {
        setEmailSent(false);
        setEmailError(true);
      });
    reset();
  };

  return (
    <section id="contact">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="contact-about">
              <h3>{t("title")}</h3>
              <p>{t("description")}</p>
              <div className="social-links">
                <a
                  href="https://twitter.com/therapphealth"
                  className="twitter"
                  aria-label="Visit Therapp Twitter account"
                >
                  <i className="bi bi-twitter" />
                </a>
                <a
                  href="https://www.facebook.com/therapp.health"
                  className="facebook"
                  aria-label="Visit Therapp Facebook account"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/therapp.health"
                  className="instagram"
                  aria-label="Visit Therapp Instagram account"
                >
                  <i className="bi bi-instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/83128689/admin"
                  className="linkedin"
                  aria-label="Visit Therapp Linkedin account"
                >
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info">
              <div>
                <i className="bi bi-geo-alt" />
                <p>{t("location")}</p>
              </div>
              <div>
                <i className="bi bi-envelope" />
                <p>{t("email")}</p>
              </div>
              <div>
                <i className="bi bi-phone" />
                <p>{t("phone")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="form">
              <Form
                className="email-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="form-group col-lg-6">
                    <input
                      type="text"
                      name="name"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      placeholder={t("name")}
                      {...register("name", {
                        required: t("name-required-error"),
                      })}
                    />
                    {errors.name?.type === "required" && (
                      <span className="error-message">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-lg-6 mt-3 mt-lg-0">
                    <input
                      type="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      name="email"
                      placeholder={t("email-field")}
                      {...register("email", {
                        required: t("email-required-error"),
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: t("email-required-error"),
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="error-message">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.subject ? "is-invalid" : ""
                    }`}
                    name="subject"
                    placeholder={t("subject")}
                    {...register("subject", {
                      required: t("subject-required-error"),
                    })}
                  />
                  {errors.subject?.type === "required" && (
                    <span className="error-message">
                      {errors.subject.message}
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className={`form-control ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    name="message"
                    rows={5}
                    placeholder={t("message")}
                    required
                    defaultValue={""}
                    {...register("message", {
                      required: t("message-required-error"),
                    })}
                  />
                  {errors.message?.type === "required" && (
                    <span className="error-message">
                      {errors.message.message}
                    </span>
                  )}
                </div>
                {emailSent && !emailError && (
                  <div className="sent-message">{t("message-sent")}</div>
                )}
                {emailError && (
                  <div className="sent-message error-message">
                    {t("message-error")}
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    title="Send Message"
                    onClick={() => {
                      setEmailSent(false);
                      setEmailError(false);
                    }}
                  >
                    {t("button")}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
